import React, { useContext, useEffect, useState } from "react";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
import Navbars from "../../component/Navbar/Navbar";
import { AuthUserContext } from "../../context/context";
import { useTranslation } from "react-i18next";
import call from "../../assets/images/call.svg";
import whatsapp from "../../assets/images/whatsapp.svg";
import address from "../../assets/images/address.svg";
import email from "../../assets/images/email.svg";
import Footer from "../../component/Footer/Footer";
import contactUsForm from "../../services/contactus/contactUsForm";
import { toast } from "react-toastify";
import getLiveSections from "../../services/api/Sections/getLiveSections";
import SectionMode from "../../component/SectionMode";
import { Spinner } from "react-bootstrap";
const ContactUs = () => {
  const { currentLocalization, setToastId } = useContext(AuthUserContext);
  const london = { lat: 51.53169092438594, lng: 0.12075493962273767 };
  const scotland = { lat: 55.90316103947342, lng: -3.5275221024666332 };
  const markers = [london, scotland];

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
    lang: "",
  });

  // Google Maps setup
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyCiSwHoCr13O6AVKbUSFQ5gjzy7DIlY7Ac",
  });

  const onMapLoad = (map) => {
    const bounds = new window.google.maps.LatLngBounds();

    markers.forEach(marker => {
      bounds.extend(new window.google.maps.LatLng(marker.lat, marker.lng));
    });

    map.fitBounds(bounds);
    setMap(map);
  };

  const [formErrors, setFormErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [sections, setSections] = useState([]);
  const [validated, setValidated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [map, setMap] = useState(null);
  const { t } = useTranslation();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const validate = () => {
    let errors = {};
    if (!formData.name) errors.name = t("validation.nameRequired");
    if (!formData.email) {
      errors.email = t("validation.emailRequired");
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = t("validation.invalidEmail");
    }
    if (!formData.message) errors.message = t("validation.messageRequired");
    setValidated(true);
    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormErrors(validate());
    setValidated(false);

    if (Object.keys(validate()).length === 0) {
      setIsSubmitting(true);
      try {
        setLoading(true);
        formData.lang = currentLocalization;
        await contactUsForm(formData);
        setFormData({
          name: "",
          email: "",
          message: "",
          lang: "",
        });
        const id = toast.success(t("contactUs.formSuccess"), {
          autoClose: false,
          closeOnClick: false,
          draggable: false,
          pauseOnHover: true,
        });
        setToastId(id);
      } catch (error) {
        const id = toast.error(t("contactUs.formError"), {
          autoClose: false,
          closeOnClick: false,
          draggable: false,
          pauseOnHover: true,
        });
        setToastId(id);
      } finally {
        setIsSubmitting(false);
        setLoading(false);
      }
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getLiveSections(
          "liveMode",
          "Contact Us",
          currentLocalization
        );
        setSections(response.data);
      } catch (error) {
        console.error("Error fetching live sections:", error);
      }
    };

    fetchData();
  }, [currentLocalization]);
  return (
    <div>
      <Navbars />
      <section className="country-image-box">
        <Container>
          <Row
            style={{ paddingTop: "80px" }}
            className={`pb-4 d-flex ${currentLocalization === "ar" ? "flex-row-reverse" : "flex-row"
              }`}
          >
            <Col
              xl={6}
              className={`mt-3  ${currentLocalization === "ar" ? "text-end" : "text-start"
                }`}
            >
              <h3 className="get-in-h3">
                <span>{t("contactUs.Get In")}</span>&nbsp;
              </h3>
              <p className="contact-message-p mt-4">{t("contactUs.message")}</p>
              <div className="contact-list-container">
                <p className="contact-message-p mt-0">
                  <ul dir={currentLocalization === "ar" ? "rtl" : "ltr"}>
                    <li ><strong>{t("contactUs.callus")}{" "}:</strong> {t("contactUs.callusText")}</li>
                    <li><strong>{t("contactUs.WhatsApp")}{" "}:</strong> {t("contactUs.WhatsAppText")}</li>
                    <li><strong>{t("contactUs.Email")}{" "}:</strong> {t("contactUs.EmailText")}</li>
                  </ul>
                </p>
              </div>
              <p className="contact-message-p mt-0">{t("contactUs.propmt")}</p>

              <p
                className={`contact-message-contact d-flex mt-4 ${currentLocalization === "ar" ? "flex-row-reverse" : "flex-row"
                  }`}
              >
                <img src={call} alt="call" />{" "}
                <span
                  className={currentLocalization === "ar" ? "pe-4" : "ps-4"}
                >
                  +44 20 8518 2329
                </span>
              </p>
              <p
                className={`contact-message-contact d-flex mt-4 ${currentLocalization === "ar" ? "flex-row-reverse" : "flex-row"
                  }`}
              >
                <a
                  href={`https://wa.me/447766540364`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={`d-flex align-items-center ${currentLocalization === "ar" ? "flex-row-reverse" : "flex-row"
                    }`}
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  <img src={whatsapp} alt="whatsapp" />{" "}
                  <span
                    className={currentLocalization === "ar" ? "pe-4" : "ps-4"}
                  >
                    +44 77 6654 0364
                  </span>
                </a>
              </p>

              <p
                className={`contact-message-contact d-flex mt-4 ${currentLocalization === "ar" ? "flex-row-reverse" : "flex-row"
                  }`}
              >
                <img src={email} alt="email" />
                <span
                  className={currentLocalization === "ar" ? "pe-4" : "ps-4"}
                >
                  info@gleesim.com
                </span>
              </p>
              <p
                className={`contact-message-contact d-flex mt-4 ${currentLocalization === "ar" ? "flex-row-reverse" : "flex-row"
                  }`}
              >
                <img src={address} alt="address" />
                <span
                  className={currentLocalization === "ar" ? "pe-4" : "ps-4"}
                >
                  <b>London:</b> Unit 4 All Cool building, 1st Floor Ripple Side commercial estate, A13
                  Barking, Greater London IG11 0RJ
                </span>
              </p>
              <p
                className={`contact-message-contact d-flex mt-4 ${currentLocalization === "ar" ? "flex-row-reverse" : "flex-row"
                  }`}
              >
                <img src={address} alt="address" />
                <span
                  className={currentLocalization === "ar" ? "pe-4" : "ps-4"}
                >
                  <b>Scotland:</b> 16 Turnbull way Livingston, West Lothian, Scotland EH54 8RB
                </span>
              </p>
            </Col>
            <Col
              xl={6}
              className={`mt-3 d-flex ${currentLocalization === "ar"
                ? "justify-content-start"
                : "justify-content-xl-end justify-content-center"
                }`}
            >
              <form
                className={`message-box text-start p-4 ${currentLocalization === "ar" ? "text-end" : "text-start"
                  }`}
                onSubmit={handleSubmit}
              >
                <h2 className="send-message-h2">
                  {t("contactUs.Send a Message")}
                </h2>
                <div className="d-flex flex-column mt-5">
                  <label className="label-text">
                    {t("signUp.part3")}{" "}
                    <span className="text-danger-asterisk">*</span>
                  </label>
                  <input
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    placeholder={t("signUp.part4")}
                    className={` mt-2 ${currentLocalization === "ar"
                      ? "name-input-right"
                      : "name-input"
                      }`}
                  />
                  {validated && !formData.name && (
                    <small className="text-danger">{formErrors.name}</small>
                  )}
                </div>
                <div className="d-flex flex-column mt-4">
                  <label className="label-text">
                    {t("contactUs.Email")}{" "}
                    <span className="text-danger-asterisk">*</span>
                  </label>
                  <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    placeholder={t("contactUs.Enter Email")}
                    className={` mt-2 ${currentLocalization === "ar"
                      ? "name-input-right"
                      : "name-input"
                      }`}
                  />
                  {validated && !formData.email && (
                    <small className="text-danger">{formErrors.email}</small>
                  )}
                </div>
                <div className="d-flex flex-column mt-4">
                  <label className="label-text">
                    {t("contactUs.Message")}{" "}
                    <span className="text-danger-asterisk">*</span>
                  </label>
                  <textarea
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    placeholder={t("contactUs.EnterMessage")}
                    className={`mt-2 ${currentLocalization === "ar"
                      ? "name-textarea-right"
                      : "name-textarea"
                      }`}
                    rows="10"
                  />
                  {validated && !formData.message && (
                    <small className="text-danger">{formErrors.message}</small>
                  )}
                </div>
                <div
                  className={`d-flex ${currentLocalization === "ar"
                    ? "justify-content-start"
                    : "justify-content-end"
                    }`}
                >
                  <button
                    type="submit"
                    className="submit-btn mt-4"
                    disabled={loading}
                  >
                    {loading ? (
                      <>
                        {currentLocalization == "ar" ? (
                          <>
                            <span className="me-2">
                              ... {t("login.part16")}
                            </span>
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />
                          </>
                        ) : (
                          <>
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />
                            <span className="ms-2">
                              {t("login.part16")} ...
                            </span>
                          </>
                        )}
                      </>
                    ) : (
                      <>{t("otpVerification.part4")}</>
                    )}
                    {/* {loading ? t("otpVerification.part4"): t("otpVerification.part4")}{} */}
                  </button>
                </div>
              </form>
            </Col>
            <Col md={12} className={`pt-5 mt-5`}>
              {isLoaded && (
                <GoogleMap
                  onLoad={onMapLoad}
                  zoom={10}
                  center={london}
                  mapContainerStyle={{ width: "100%", height: "400px" }}
                >
                  {markers.map((marker, index) => (
                    <Marker key={index} position={marker} />
                  ))}
                </GoogleMap>
              )}
            </Col>
          </Row>
          <SectionMode allSectionWithLang={sections} />
          <div className="mt-5">
            <Footer />
          </div>
        </Container>
      </section>
    </div>
  );
};

export default ContactUs;
